<template>
  <v-app id="app">

    <AppNavigationDrawer
      v-if="isAuth"
      :show="showNavigationDrawer"
      @toggle="toggleNavigationDrawer"
    />

    <v-app-bar
      v-else
      app
      clipped-left
      clipped-right
      color="white"
    >
      <img
        class="app-logo"
        src="@/assets/SchILD_klassenbuch-Logo-CMYK.png"
        alt="schildweb klassenbuchmodul logo"
      />
      <v-spacer />
      <img
        class="ribeka-logo"
        src="@/assets/ribeka_logo_146x32.png"
        alt="ribeka logo"
      />
    </v-app-bar>

    <v-main>
      <AppLoader
        v-if="navigating"
      />

      <div
        v-else-if="navigationFailed"
        class="d-flex flex-column justify-center align-center fill-height"
      >
        <v-icon size="96">mdi-wifi-alert</v-icon>
        Wir scheinen offline zu sein
      </div>

      <v-container
        v-else
        class="pa-0"
        fluid
      >
        <router-view />
      </v-container>
    </v-main>

  </v-app>
</template>


<script>
import AppNavigationDrawer from '@/components/app-navigation-drawer'
import AppLoader from '@/components/app-loader'

export default {
  name: 'App',
  components: {
    AppNavigationDrawer,
    AppLoader
  },
  computed: {
    isAuth() {
      return this.$store.getters['isAuth']
    },
    navigating() {
      return this.$store.state.navigating
    },
    navigationFailed() {
      return this.$store.state.navigationFailed
    },
    showNavigationDrawer() {
      return this.$store.state.showNavigationDrawer
    }
  },
  methods: {
    toggleNavigationDrawer(show) {
      this.$store.commit('TOGGLE_NAVIGATION_DRAWER', show)
    }
  }
}
</script>


<style lang="scss" scoped>
.container {
  height: 100%;
}

.app-logo {
  height: 50px;
  width: 85px;
}

.ribeka-logo {
  width: 146px;
  height: 32px;
}
</style>


<style lang="scss">
@import '@/scss/variables.scss';

@media screen {
  html {
    -webkit-text-size-adjust: none;
    touch-action: manipulation;
  }

  body,
  #app {
    background-color: $body-background-color;
  }

  html,
  body,
  #app,
  .v-application--wrap,
  .v-application--wrap > .v-main,
  .v-application--wrap > .v-main > .v-main__wrap,
  .v-application--wrap > .v-main > .v-main__wrap > .container {
    width: 100%;
    height: 100%;
    min-height: 100%;
    overflow: hidden;
  }

  .v-application--wrap  > .v-navigation-drawer {
    max-height: 100%;
  }

  .v-avatar > * + .v-image {
    position: absolute;
  }

  .v-select {
    font-size: 14px;
  }
}

@media print {
  .v-application--wrap > .v-main {
    padding: 0 !important;
  }

  .print-no-shadow {
    box-shadow: none !important;
  }

  .print-no-border {
    border-radius: 0 !important;
    border: none !important;
  }
}
</style>
