<template>
  <v-navigation-drawer
    app
    clipped
    touchless
    width="250"
    :value="show"
    :permanent="!mobile"
    :mini-variant.sync="mini"
    class="d-print-none"
    @input="$emit('toggle', $event)"
  >

    <template v-slot:prepend>
      <v-list dense>
        <v-list-item class="pa-0">
          <img
            class="app-logo"
            :class="{mini}"
            src="@/assets/SchILD_klassenbuch-Logo-CMYK.png"
            alt="SchILDweb Klassenbuchmodul Logo"
          />
          <v-spacer />
          <v-btn
            v-if="mobile"
            icon
            class="mr-2"
            @click.stop="$emit('toggle', false)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            v-else
            icon
            class="mr-4"
            @click.stop="mini = !mini"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>

        <v-divider />

        <UserMenu />

        <v-divider />
      </v-list>
    </template>

    <MainMenu />

    <template v-slot:append>
      <FooterMenu />
    </template>

  </v-navigation-drawer>
</template>


<script>
import UserMenu from '@/components/app-navigation-drawer/user-menu'
import MainMenu from '@/components/app-navigation-drawer/main-menu'
import FooterMenu from '@/components/app-navigation-drawer/footer-menu'

export default {
  name: 'AppNavigationDrawer',
  components: {
    UserMenu,
    MainMenu,
    FooterMenu
  },
  props: {
    show: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    mini: false
  }),
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mobile
    }
  }
}
</script>


<style lang="scss" scoped>
.app-logo.mini {
  width: 48px;
  height: 28px;
  margin: 12px auto;
}

@media screen and (max-width: 1263px) {
  .app-logo:not(.mini) {
    width: 80px;
    height: 47px;
    margin: 4px 12px;
  }
}

@media screen and (min-width: 1264px) {
  .app-logo:not(.mini) {
    width: 100px;
    height: 59px;
    margin: 16px;
  }
}
</style>
