import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.min.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import de from 'vuetify/lib/locale/de'

Vue.use(Vuetify)

const opts = {
  icons: {
    iconfont: 'mdi'
  },
  lang: {
    locales: {de},
    current: 'de'
  },
  theme: {
    options: {customProperties: true}
  }
}

export default new Vuetify(opts)
