<template>
  <v-list-item-group color="primary">
    <template v-for="{ routeName, icon, title, items } in menuItems">

      <v-list-item
        v-if="routeName"
        :key="routeName"
        :to="{name: routeName}"
      >
        <v-list-item-icon>
          <v-icon v-text="icon" />
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title v-text="title" />
        </v-list-item-content>
      </v-list-item>

      <v-list-group
        v-if="items"
        :key="title"
        :prepend-icon="icon"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-title v-text="title" />
        </template>
        <v-list-item
          v-for="childItem in items"
          :key="childItem.routeName"
          :to="{name: childItem.routeName}"
        >
          <v-list-item-title
            class="pl-3"
            v-text="childItem.title"
          />
        </v-list-item>
      </v-list-group>

    </template>
  </v-list-item-group>
</template>


<script>
export default {
  name: 'NavigationGroup',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    menuItems() {
      const createItem = item => {
        if (typeof item == 'string') {
          const { route } = this.$router.resolve({name: item})
          return {
            routeName: item,
            icon: route.meta.icon,
            title: route.meta.title
          }
        }
        return {
          ...item,
          items: item.items.map(createItem)
        }
      }
      return this.items.map(createItem)
    }
  }
}
</script>
