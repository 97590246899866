<template>
  <div class="app-loader">
    <b/>
    <b/>
    <b/>
  </div>
</template>


<script>
export default {
  name: 'AppLoader'
}
</script>


<style lang="scss" scoped>
.app-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.03);
}
</style>
