import { dateToString } from '@/helpers/datetime'

export default class AcademicYear {
  constructor(rawData) {
    Object.assign(this, {
      ...rawData,
      startsOn: rawData.startsOn || `${rawData.year}-08-01`,
      endsOn: rawData.endsOn || `${rawData.year + 1}-07-31`
    })
  }

  isRunning(date = new Date()) {
    if ('string' != typeof date) {
      date = dateToString(date)
    }
    return this.startsOn <= date && date <= this.endsOn
  }
}
