const contextObjectCollections = [
  {
    type: 'SchoolClass',
    collection: 'schoolClasses',
    title: 'Klasse',
    icon: 'mdi-account-group-outline'
  }, {
    type: 'Course',
    collection: 'courses',
    title: 'Kurs',
    icon: 'mdi-school-outline'
  }, {
    type: 'Classroom',
    collection: 'classrooms',
    title: 'Raum',
    icon: 'mdi-map-marker-outline'
  }, {
    type: 'Teacher',
    collection: 'teachers',
    title: 'Lehrkraft',
    icon: 'mdi-account-outline'
  }
]

export default contextObjectCollections
