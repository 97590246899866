import {
  api,
  getAllAcademicYears,
  getAllSections,
  getAllAbsenceReasons,
  getAllClassrooms,
  getAllTeachers,
  getAllSchoolSubjects
} from '@/api'
import AcademicYear from '@/entities/academic-year'
import Section from '@/entities/section'
import DayPlan from '@/entities/day-plan'
import Teacher from '@/entities/teacher'
import { dateFromString } from '@/helpers/datetime'

const services = {
  academicYears: getAllAcademicYears,
  sections: getAllSections,
  absenceReasons: getAllAbsenceReasons,
  classrooms: getAllClassrooms,
  teachers: getAllTeachers,
  schoolSubjects: getAllSchoolSubjects
}

const entities = {
  academicYears: AcademicYear,
  sections: Section,
  teachers: Teacher
}


const state = {
  pending: [],
  academicYears: [],
  absenceReasons: [],
  absenceReasonsMap: new Map(),
  classrooms: [],
  classroomsMap: new Map(),
  teachers: [],
  teachersMap: new Map(),
  schoolSubjects: [],
  schoolSubjectsMap: new Map(),
  schoolClasses: [],
  schoolClassesMap: new Map(),
  courses: [],
  coursesMap: new Map(),
  sections: [],
  sectionsMap: new Map(),
  dayPlans: [],
  dayPlansMap: new Map(),
  dayPlanSlotsMap: new Map()
}


const getters = {

  loading(state) {
    return state.pending.length > 0
  },

  absenceReasonByIri(state) {
    return iri => state.absenceReasonsMap.get(iri)
  },

  classroomByIri(state) {
    return iri => state.classroomsMap.get(iri)
  },

  teacherByIri(state) {
    return iri => state.teachersMap.get(iri)
  },

  schoolSubjectByIri(state) {
    return iri => state.schoolSubjectsMap.get(iri)
  },

  schoolClassByIri(state) {
    return iri => state.schoolClassesMap.get(iri)
  },

  courseByIri(state) {
    return iri => state.coursesMap.get(iri)
  },

  sectionByIri(state) {
    return iri => state.sectionsMap.get(iri)
  },

  dayPlanByIri(state) {
    return iri => state.dayPlansMap.get(iri)
  },

  dayPlanSlotByIri(state) {
    return iri => state.dayPlanSlotsMap.get(iri)
  },

  sectionByDate(state) {
    return dateString => state.sections.find(s => s.isRunning(dateString)) || null
  },

  sectionsByDates(state, getters) {
    return dates => {
      const { sectionByDate } = getters
      const findSection = dateString => sectionByDate(dateString)
      const unique = array => [...new Set(array)]
      const notEmpty = item => item
      return unique(dates.map(findSection)).filter(notEmpty)
    }
  },

  dayPlanByDate(state, getters) {
    return dateString => {
      const section = getters.sectionByDate(dateString)
      return section && state.dayPlans.findLast(({ startsOn, endsOn }) =>
        (!startsOn || startsOn <= dateString) &&
        (!endsOn || dateString <= endsOn)
      ) || null
    }
  },

  dayPlanSlotsByDate(state, getters) {
    return dateString => {
      const dayOfWeek = dateFromString(dateString).getDay()
      const byDayOfWeek = item => item.dayOfWeek === dayOfWeek
      const byStartTime = ({ startsAt: a }, { startsAt: b }) => (a < b ? -1 : (a > b ? 1 : 0))
      const dayPlan = getters.dayPlanByDate(dateString)
      return (dayPlan?.dayPlanSlots || []).filter(byDayOfWeek).sort(byStartTime) || []
    }
  },

  studentSchoolClassByAcademicYear(state, getters) {
    return (student, academicYear) => {
      const academicYearIri = academicYear?.['@id']
      for (const { schoolClass: schoolClassIri } of student.schoolClassStudents) {
        const schoolClass = getters.schoolClassByIri(schoolClassIri)
        if (schoolClass?.academicYear === academicYearIri) return schoolClass
      }
      return null
    }
  }

}


const mutations = {

  ADD_PENDING(state, collectionName) {
    if (state.pending.includes(collectionName)) return
    state.pending = [...state.pending, collectionName]
  },

  REMOVE_PENDING(state, collectionName) {
    if (!state.pending.includes(collectionName)) return
    state.pending = state.pending.filter(item => item !== collectionName)
  },

  SET_ITEMS(state, { collectionName, items }) {
    state[collectionName] = items
    const mapName = `${collectionName}Map`
    if (mapName in state) {
      state[mapName] = new Map(items.map(item => [item['@id'], item]))
    }
  },

  SET_DAYPLANS(state, dayPlans) {
    state.dayPlans = dayPlans
    state.dayPlansMap = new Map(dayPlans.map(dayPlan => [dayPlan['@id'], dayPlan]))
    state.dayPlanSlotsMap = new Map(
      [].concat(
        ...dayPlans.map(dayPlan => dayPlan.dayPlanSlots)
      ).map(dayPlanSlot => [dayPlanSlot['@id'], dayPlanSlot])
    )
  }

}


const actions = {

  async fetchCollection({ state, commit }, collectionName) {
    if (state.pending.includes(collectionName)) return

    const getCollection = services[collectionName]

    if (!getCollection) {
      throw new Error('Unknown collection: ' + collectionName)
    }

    commit('ADD_PENDING', collectionName)
    try {
      let items = (await getCollection()).data['hydra:member']
      if (collectionName in entities) {
        items = items.map(rawData => new entities[collectionName](rawData))
      }
      commit('SET_ITEMS', {collectionName, items})
    } catch (e) {
      console.error(e)
    } finally {
      commit('REMOVE_PENDING', collectionName)
    }
  },

  async fetchCollectionOnce({ state, dispatch }, propName) {
    if ('string' == typeof propName) {
      if (!(propName in services)) {
        throw new Error('Unknown collection: ' + propName)
      }
      if (state[propName].length === 0) {
        await dispatch('fetchCollection', propName)
      }
    } else if (Array.isArray(propName)) {
      await Promise.all(
        propName.map(prop => dispatch('fetchCollectionOnce', prop))
      )
    }
  },

  async fetchSchoolClassesByAcademicYear({ commit, state }, academicYear) {
    if (!academicYear) return
    const url = `${academicYear['@id']}/school_classes`
    if (state.schoolClasses.url === url || state.pending.includes(url)) return
    commit('ADD_PENDING', url)
    try {
      const schoolClasses = (await api.get(url, {
        params: {
          pagination: false,
          'order[position]': 'asc',
          'order[code]': 'asc'
        }
      })).data['hydra:member']
      schoolClasses.url = url
      commit('SET_ITEMS', {collectionName: 'schoolClasses', items: schoolClasses})
    } catch (e) {
      console.error(e)
    } finally {
      commit('REMOVE_PENDING', url)
    }
  },

  async fetchCoursesBySection({ state, commit }, section) {
    if (!section) return
    const url = `${section['@id']}/courses`
    if (state.courses.url === url || state.pending.includes(url)) return
    commit('ADD_PENDING', url)
    try {
      const courses = (await api.get(url, {
        params: {
          pagination: false,
          'order[position]': 'asc',
          'order[code]': 'asc'
        }
      })).data['hydra:member']
      courses.url = url
      commit('SET_ITEMS', {collectionName: 'courses', items: courses})
    } catch (e) {
      console.error(e)
    } finally {
      commit('REMOVE_PENDING', url)
    }
  },

  async fetchDayPlansBySection({ state, commit }, section) {
    if (!section) return
    const url = `${section['@id']}/day_plans`
    if (state.dayPlans.url === url || state.pending.includes(url)) return
    commit('ADD_PENDING', url)
    try {
      const rawDayPlans = (await api.get(url)).data
      const dayPlans = rawDayPlans.map(rawData => new DayPlan(rawData))
      dayPlans.url = url
      commit('SET_DAYPLANS', dayPlans)
    } catch (e) {
      console.error(e)
    } finally {
      commit('REMOVE_PENDING', url)
    }
  }

}


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
