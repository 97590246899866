import '@/scss/global-styles.scss'
import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import App from '@/App'
import { store } from '@/store'
import { router } from '@/router'

Vue.config.productionTip = false

store.dispatch('auth/autologin').then(() => {
  new Vue({
    render: h => h(App),
    vuetify,
    store,
    router,
    created() {
      this.$store.dispatch('auth/initInterceptor')
    }
  }).$mount('#app')
})
