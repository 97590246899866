export default class User {
  constructor(rawData) {
    Object.assign(this, rawData)
    this.roles = this.roles || []
    this.createdAt = this.createdAt ? new Date(this.createdAt) : null
    this.lastLogin = this.lastLogin ? new Date(this.lastLogin) : null
  }

  hasRole(...roles) {
    return roles.some(role => !!role && this.roles.includes(role))
  }
}
