<template>
  <v-list
    nav
    dense
  >
    <NavigationGroup :items="menuItems" />
  </v-list>
</template>


<script>
import NavigationGroup from '@/components/app-navigation-drawer/navigation-group'

export default {
  name: 'AppNavigationDrawerFooterMenu',
  components: {
    NavigationGroup
  },
  computed: {
    menuItems() {
      return [
        this.$store.getters['isAdmin'] && 'manage-users',
        'info',
      ]
      .filter((item) => !!item)
    },
  },
}
</script>
