import axios from 'axios'

const knownErrorProps = [
  'details', 'hydra:description', 'description', 'error', 'message'
]

const knownErrorMessages = {
  'Invalid credentials.': 'Ungültige E-Mail oder Passwort!'
}


export const api = axios.create({
  headers: {Accept: 'application/ld+json'},
  withCredentials: true
})


export function getErrorReason(error) {
  const msg = 'Versuchen Sie bitte nochmal später.'
  if (error.response) {
    const { status } = error.response
    if (403 === status) {
      return 'Sie haben keine Berechtigung für die Aktion.'
    }
    if (500 <= status) {
      return `Server Fehler! ${msg}`
    }
    for (const prop of knownErrorProps) {
      const propValue = error.response.data[prop]
      if (propValue) {
        return knownErrorMessages[propValue] || propValue
      }
    }
  } else if (error.request) {
    return `Verbindungsfehler! ${msg}`
  }
  return 'Fehler!'
}


export function getAllAbsenceReasons() {
  return api.get('/api/absence_reasons', {
    params: {
      pagination: false,
      'order[position]': 'asc'
    }
  })
}


export function getAllAcademicYears() {
  return api.get('/api/academic_years', {
    params: {
      pagination: false,
      'order[year]': 'asc'
    }
  })
}


export function getAllClassrooms() {
  return api.get('/api/classrooms', {
    params: {
      pagination: false,
      'order[code]': 'asc'
    }
  })
}


export function getAllTeachers() {
  return api.get('/api/teachers', {
    params: {
      pagination: false,
      'order[lastName]': 'asc',
      'order[firstName]': 'asc',
    }
  })
}


export function getAllSchoolSubjects() {
  return api.get('/api/school_subjects', {
    params: {
      pagination: false,
      'order[name]': 'asc'
    }
  })
}


export function getAllSections() {
  return api.get('/api/sections', {
    params: {
      pagination: false,
      'order[startsOn]': 'asc'
    }
  })
}
