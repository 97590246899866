// General user roles
const ROLE_ADMIN = 'ROLE_ADMIN'
const ROLE_TEACHER = 'ROLE_TEACHER'
const ROLE_CLASSTEACHER = 'ROLE_CLASSTEACHER'

// Functional user roles
const ROLE_EDIT_LESSON_DATA_AS_ADMIN = 'ROLE_EDIT_LESSON_DATA_AS_ADMIN'
const ROLE_EDIT_LESSON_DATA_AS_TEACHER = 'ROLE_EDIT_LESSON_DATA_AS_TEACHER'
const ROLE_EDIT_LESSON_REPLECEMENT_AS_ADMIN = 'ROLE_EDIT_LESSON_REPLECEMENT_AS_ADMIN'
const ROLE_EDIT_STUDENT_ABSENCE_AS_ADMIN = 'ROLE_EDIT_STUDENT_ABSENCE_AS_ADMIN'
const ROLE_EDIT_STUDENT_ABSENCE_AS_CLASSTEACHER = 'ROLE_EDIT_STUDENT_ABSENCE_AS_CLASSTEACHER'
const ROLE_EDIT_STUDENT_ABSENCE_AS_TEACHER = 'ROLE_EDIT_STUDENT_ABSENCE_AS_TEACHER'


const getters = {

  canUpdateActualLesson(state, getters, { user }) {
    return actualLesson => {
      if (actualLesson && user) {
        if (user.hasRole(ROLE_ADMIN, ROLE_EDIT_LESSON_DATA_AS_ADMIN)) {
          return true
        }
        if (
          user.hasRole(ROLE_TEACHER, ROLE_EDIT_LESSON_DATA_AS_TEACHER)
          && actualLesson.hasTeacher(user.teacher)
        ) {
          return true
        }
      }
      return false
    }
  },

  canWriteStudentAbsenceByActualLesson(state, getters, { user }) {
    return (actualLesson, student) => {
      if (actualLesson && student && user) {
        if (user.hasRole(ROLE_ADMIN, ROLE_EDIT_STUDENT_ABSENCE_AS_ADMIN)) {
          return true
        }
        if (
          user.hasRole(ROLE_TEACHER, ROLE_EDIT_STUDENT_ABSENCE_AS_TEACHER)
          && actualLesson.hasTeacher(user.teacher)
        ) {
          return true
        }
        if (user.hasRole(ROLE_CLASSTEACHER, ROLE_EDIT_STUDENT_ABSENCE_AS_CLASSTEACHER)) {
          const { teacher } = user
          if (teacher) {
            const { section } = actualLesson
            const sectionLink = section && (student.sectionLinks || []).find(l => l.section === section)
            const { classTeacher: t1, classTeacher2: t2 } = sectionLink || {}
            if ((t1 && t1 === teacher) || (t2 && t2 === teacher)) return true
          }
        }
      }
      return false
    }
  },

  canWriteStudentAbsenceByStudent(state, getters, { user }) {
    return student => {
      if (user) {
        if (user.hasRole(ROLE_ADMIN, ROLE_EDIT_STUDENT_ABSENCE_AS_ADMIN)) {
          return true
        }
        if (user.teacher && user.hasRole(ROLE_CLASSTEACHER, ROLE_EDIT_STUDENT_ABSENCE_AS_CLASSTEACHER)) {
          if (!student) return true
          const { teacher } = user
          const hasThisClassTeacher = sectionLink => {
            const { classTeacher: t1, classTeacher2: t2 } = sectionLink
            return (t1 && t1 === teacher) || (t2 && t2 === teacher)
          }
          if ((student.sectionLinks || []).some(hasThisClassTeacher)) return true
        }
      }
      return false
    }
  },

  canWriteActualLessonReplacement(state, getters, { user }) {
    return !!user?.hasRole(ROLE_ADMIN, ROLE_EDIT_LESSON_REPLECEMENT_AS_ADMIN)
  },

  canWriteWeekComment(state, getters, { user }) {
    return !!user?.hasRole(ROLE_ADMIN, ROLE_CLASSTEACHER, ROLE_TEACHER)
  },

  canWriteWeekReport(state, getters, { user }) {
    return !!user?.hasRole(ROLE_ADMIN, ROLE_CLASSTEACHER, ROLE_TEACHER)
  }

}


export default {
  getters
}
