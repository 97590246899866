<template>
  <v-list
    nav
    dense
  >
    <NavigationGroup :items="menuItems" />
  </v-list>
</template>


<script>
import NavigationGroup from '@/components/app-navigation-drawer/navigation-group'

export default {
  name: 'AppNavigationDrawerMainMenu',
  components: {
    NavigationGroup
  },
  computed: {
    menuItems() {
      const { useWeekReports } = this.$store.state.school || {}
      return [
        // 'home',
        'schedule',
        useWeekReports ? 'week-reports' : 'lessons-overview',
        'absences',
        'book'
      ]
    }
  }
}
</script>
