import {
  cloneDate,
  dateFromString,
  dateToString,
  dayOfWeek,
  findMondayThisWeek,
  getWeekOfYear,
  monthShortName
} from '@/helpers/datetime'


const state = {
  academicYear: null,
  section: null,
  startDate: null,
  endDate: null
}


const getters = {

  sectionsByAcademicYear: (state, getters, rootState) => academicYear => {
    const { sections } = rootState.common
    const yearIri = academicYear?.['@id']
    return yearIri && sections.filter(s => s.academicYear === yearIri) || []
  },

  monthsBySection: () => section => {
    const months = []
    if (!section) return months
    const today = dateToString(new Date())
    const date = dateFromString(section.startsOn.replace(/\d\d$/, '01'))
    const sectionEndDate = dateFromString(section.endsOn)
    while (date <= sectionEndDate) {
      const startsOn = dateToString(date)
      date.setMonth(date.getMonth() + 1)
      let endsOn = cloneDate(date)
      endsOn.setDate(endsOn.getDate() - 1)
      endsOn = dateToString(endsOn)
      months.push({
        monthId: startsOn.replace(/-\d\d$/, ''),
        text: monthShortName(endsOn),
        startsOn,
        endsOn,
        isCurrent: startsOn <= today && today <= endsOn
      })
    }
    return months
  },

  weeksBySection: (state, getters) => section => {
    return section && getters.weeksByDates(section.startsOn, section.endsOn) || []
  },

  weeksByDates: () => (startDate, endDate) => {
    if (!(startDate && endDate)) return []
    const weeks = []
    const today = dateToString(new Date())
    const date = findMondayThisWeek(startDate)
    endDate = dateFromString(endDate)
    while (date <= endDate) {
      const startsOn = dateToString(date)
      const weekOfYear = getWeekOfYear(date)
      const weekNumber = weekOfYear.week
      date.setDate(date.getDate() + 7)
      let endsOn = cloneDate(date)
      endsOn.setDate(endsOn.getDate() - 1)
      endsOn = dateToString(endsOn)
      weeks.push({
        weekId: weekOfYear.toString(),
        text: `KW${weekNumber}`,
        weekNumber,
        startsOn,
        endsOn,
        isCurrent: startsOn <= today && today <= endsOn
      })
    }
    return weeks
  },

  daysBySection: (state, getters, rootState, rootGetters) => section => {
    const days = []
    const startDateString = section?.startsOn
    const endDateString = section?.endsOn

    if (!(startDateString && endDateString)) return days

    const today = dateToString(new Date())
    const weekendDays = [dayOfWeek.SUNDAY, dayOfWeek.SATURDAY]

    const dayPlanByDate = rootGetters['common/dayPlanByDate']
    const dayPlanSlotsByDate = rootGetters['common/dayPlanSlotsByDate']

    for (
      const date = dateFromString(startDateString), endDate = dateFromString(endDateString);
      date <= endDate;
      date.setDate(date.getDate() + 1)
    ) {
      const dateString = dateToString(date)
      const dayOfWeek = date.getDay()
      const dayPlan = dayPlanByDate(dateString)
      const dayPlanSlots = dayPlan && dayPlanSlotsByDate(dateString) || []

      if (!(dayPlanSlots?.length > 0) && weekendDays.includes(dayOfWeek)) continue

      days.push({
        date: cloneDate(date),
        dateString,
        isToday: dateString === today,
        isFuture: today < dateString,
        dayOfWeek,
        section: section?.isRunning(dateString) ? section : null,
        dayPlan,
        dayPlanSlots
      })
    }

    return days
  },

  allowedDatesBySection: (state, getters) => section => {
    const days = getters.daysBySection(section)
    return days?.filter(d => d.dayPlan && !d.dayPlan.isHoliday).map(d => d.dateString) || []
  },

  allowedDates(state, getters) {
    return getters.allowedDatesBySection(state.section)
  },

  activeDay(state) {
    const { startDate, endDate } = state
    return startDate === endDate && startDate || null
  },

  activeWeek(state, getters) {
    const { startDate, endDate, section } = state
    if (!(startDate && endDate && section)) return null
    const weeks = getters.weeksBySection(section)
    return weeks.find(w => startDate === w.startsOn && endDate === w.endsOn)
  },

  activeMonth(state, getters) {
    const { startDate, endDate, section } = state
    if (!(startDate && endDate && section)) return null
    const months = getters.monthsBySection(section)
    return months.find(m => startDate === m.startsOn && endDate === m.endsOn)
  }

}


const mutations = {

  SET_STATE(state, { academicYear = null, section = null, startDate = null, endDate = null }) {
    state.academicYear = academicYear
    state.section = academicYear && section
    state.startDate = state.section && startDate
    state.endDate = state.startDate && endDate
  },

  SET_DATES(state, date) {
    if ('string' === typeof date) {
      state.startDate = state.endDate = date
    } else {
      state.startDate = date?.startDate || null
      state.endDate = date?.endDate || null
    }
  }

}


const actions = {

  setNearestAcademicYear({ commit, rootState }, date = new Date()) {
    const year = date.getFullYear() - (date.getMonth() < 7)
    const { academicYears } = rootState.common
    const academicYear = academicYears.findLast(y => y.year <= year) || academicYears[0] || null
    commit('SET_STATE', {academicYear})
  },

  setNearestSection({ commit, getters, state }, date = new Date()) {
    const { academicYear } = state
    if (!academicYear) return
    const dateString = dateToString(date)
    const sections = getters.sectionsByAcademicYear(academicYear)
    const section = sections.findLast(s => s.startsOn <= dateString) || sections[0] || null
    commit('SET_STATE', {academicYear, section})
  },

  setNearestSchoolMonth({ commit, getters, state }, date = new Date()) {
    if (!state.section) return
    const dateString = dateToString(date)
    const months = getters.monthsBySection(state.section)
    const month = months.findLast(m => m.startsOn <= dateString) || months[0] || null
    if (month) {
      commit('SET_DATES', {startDate: month.startsOn, endDate: month.endsOn})
    }
  },

  setNearestSchoolWeek({ commit, getters, state }, date = new Date()) {
    if (!state.section) return
    const dateString = dateToString(date)
    const weeks = getters.weeksBySection(state.section)
    const week = weeks.findLast(w => w.startsOn <= dateString) || weeks[0] || null
    if (week) {
      commit('SET_DATES', {startDate: week.startsOn, endDate: week.endsOn})
    }
  },

  setNearestSchoolDay({ commit, getters, state }, date = new Date()) {
    const { section } = state
    if (!section) return
    const srcDate = dateToString(date)
    const { allowedDates } = getters
    const dateString = allowedDates.findLast(dateString => dateString <= srcDate) || allowedDates[0] || null
    if (dateString) commit('SET_DATES', dateString)
  }

}


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
