import { dateToString } from '@/helpers/datetime'

export default class Section {
  constructor(rawData) {
    Object.assign(this, rawData)
    this.name = 'Abschnitt ' + this.number
    this.nameShort = 'Abs. ' + this.number
    this.calendarYear = this.startsOn.replace(/-\d\d-\d\d$/, '')
  }

  isRunning(date = new Date()) {
    if ('string' != typeof date) {
      date = dateToString(date)
    }
    return this.startsOn <= date && date <= this.endsOn
  }
}
