import { LOCALE } from '@/config'
export const dayOfWeek = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6
}

const weekendDays = [dayOfWeek.SATURDAY, dayOfWeek.SUNDAY]


export function cloneDate(date) {
  return new Date(date.getTime())
}


export function findDayOfWeek(date, dayOfWeekIndex) {
  date = dateFromString(date)
  const targetDate = cloneDate(date)
  const shift = n => ((6 + n) % 7)
  const offset = shift(dayOfWeekIndex) - shift(date.getDay())
  targetDate.setDate(date.getDate() + offset)
  return targetDate
}


export function findMondayThisWeek(date) {
  date = dateFromString(date)
  return findDayOfWeek(date, dayOfWeek.MONDAY)
}


export function getWeekOfYear(srcDate) {
  srcDate = dateFromString(srcDate)
  const date = cloneDate(srcDate)
  date.setHours(0, 0, 0, 0)
  // Thursday in current week decides the year
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7)
  // January 4 is always in week 1
  const week1 = new Date(date.getFullYear(), 0, 4)
  return {
    year: week1.getFullYear(),
    // Adjust to Thursday in week 1 and count number of weeks from date to week1
    week: 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7),
    toString() {
      const pad = n => n < 10 ? '0' + n : String(n)
      return `${this.year}-W${pad(this.week)}`
    }
  }
}


export function firstDateOfWeek(year, week) {
  const jan1 = new Date(year, 0, 1)
  const thursday = findDayOfWeek(jan1, dayOfWeek.THURSDAY)
  const weeksOffset = thursday.getFullYear() !== year ? week : week - 1
  thursday.setDate(thursday.getDate() + weeksOffset * 7)
  return findDayOfWeek(thursday, dayOfWeek.MONDAY)
}


export function dateFromString(string) {
  if ('string' != typeof string) return string
  const matches = string.match(/^(\d{4})-(\d{2})-(\d{2})(?:[T\s](\d{2}):(\d{2})(?::(\d{2}))?)?/)
  if (!matches) return
  const [, year, month, day, hours, minutes, seconds] = matches
  return new Date(
    +year,
    month - 1,
    +day,
    hours ? +hours : null,
    minutes ? +minutes : null,
    seconds ? +seconds : null
  )
}


export function dateToString(date) {
  if ('string' === typeof date) return date
  const pad = n => n < 10 ? '0' + n : String(n)
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`
}


export function formatDate(date, options = {dateStyle: 'medium'}) {
  date = dateFromString(date)
  return date?.toLocaleString(LOCALE, options)
}


export function dateRangeToArray(date1, date2) {
  date1 = dateFromString(date1)
  date2 = dateFromString(date2)
  const dates = []
  for (const date = cloneDate(date1); date <= date2; date.setDate(date.getDate() + 1)) {
    dates.push(cloneDate(date))
  }
  return dates
}


export function dateRangeToString(date1, date2) {
  const fmt = value => value ? formatDate(value) : '...'
  return `Von ${fmt(date1)} bis ${fmt(date2)}`
}


export function timeStringToMinutes(timeString /* hh:mm */) {
  const parts = timeString.split(':').map(part => +part)
  return parts[0] * 60 + parts[1]
}


export function dateForHumans(date, options = {}) {
  date = dateFromString(date)
  const defaultOptions = {weekday: 'long', month: 'long', day: 'numeric'}
  if (date.getFullYear() !== (new Date()).getFullYear()) {
    defaultOptions.year = 'numeric'
  }
  const str = date.toLocaleString(LOCALE, {...defaultOptions, ...options})
  return str.charAt(0).toUpperCase() + str.slice(1)
}


export function monthShortName(date) {
  if ('string' == typeof date) {
    if (/^\d{4}-\d{2}$/.test(date)) {
      date += '-01'
    }
    date = dateFromString(date)
  }
  return date.toLocaleString(LOCALE, {month: 'short'})
}


export function isWeekend(date) {
  date = dateFromString(date)
  return weekendDays.includes(date.getDay())
}
