<template>
  <v-menu
    offset-y
    max-width="225"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-item
        class="px-2"
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-avatar color="indigo">
          <v-img
            v-if="user.avatar"
            :src="user.avatar"
          />
          <v-icon v-else>mdi-account</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="user.displayName" />
        </v-list-item-content>

        <v-list-item-action class="mr-1 mr-lg-3">
          <v-icon>mdi-menu-down</v-icon>
        </v-list-item-action>
      </v-list-item>
    </template>
    <v-list dense>

      <NavigationGroup :items="menuItems" />

      <v-divider />

      <v-list-item @click="logout()">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Abmelden</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>


<script>
import NavigationGroup from '@/components/app-navigation-drawer/navigation-group'

export default {
  name: 'AppNavigationDrawerUserMenu',
  components: {
    NavigationGroup
  },
  data: () => ({
    menuItems: [
      'user-profile'
    ]
  }),
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
    }
  }
}
</script>
