import Vue from 'vue'
import Vuex from 'vuex'
import accessRules from '@/store/access-rules.module'
import auth from '@/store/auth.module'
import common from '@/store/common.module'
import schoolCalendar from '@/store/school-calendar.module'
import resetPassword from '@/store/reset-password.module'
import manage from '@/store/manage.module'
import { dateToString, dateFromString, getWeekOfYear } from '@/helpers/datetime'
import contextObjectCollections from '@/store/context-object-collections'

Vue.use(Vuex)

export const store = new Vuex.Store({

  modules: {
    accessRules,
    common,
    schoolCalendar,
    auth,
    resetPassword,
    manage,
  },


  state: {
    navigating: null,
    navigationFailed: null,
    user: null,
    school: null,
    contextObject: null,
    showNavigationDrawer: 1264 <= window.innerWidth,
    currentDate: new Date()
  },


  getters: {
    isAuth(state) {
      return !!state.user
    },
    isAdmin(state) {
      return !!state.user?.hasRole('ROLE_ADMIN')
    },
    currentDateString({ currentDate }) {
      return dateToString(currentDate)
    },
    currentYear({ currentDate }) {
      return currentDate.getFullYear()
    },
    currentWeek({ currentDate }) {
      return getWeekOfYear(currentDate).week
    },
    currentDayOfWeek({ currentDate }) {
      return currentDate.getDay()
    }
  },


  mutations: {

    SET_NAVIGATION_STARTED(state) {
      state.navigationFailed = null
      state.navigating = true
    },

    SET_NAVIGATION_DONE(state) {
      state.navigating = false
    },

    SET_NAVIGATION_FAILED(state) {
      state.navigationFailed = true
      state.navigating = false
    },

    SET_USER(state, user) {
      state.user = user
    },

    SET_SCHOOL(state, school) {
      state.school = school
    },

    SET_CONTEXT_OBJECT(state, contextObject) {
      state.contextObject = contextObject
    },

    TOGGLE_NAVIGATION_DRAWER(state, show) {
      state.showNavigationDrawer = typeof show == 'boolean' ? show : !state.showNavigationDrawer
    },

    SET_CURRENT_DATE(state, date) {
      if (typeof date == 'string') {
        date = dateFromString(date)
      }
      if (date) {
        state.currentDate = date
      }
    }

  },


  actions: {

    navigationStarted({ commit }) {
      commit('SET_NAVIGATION_STARTED')
    },

    navigationDone({ commit }) {
      commit('SET_NAVIGATION_DONE')
    },

    navigationFailed({ commit }) {
      commit('SET_NAVIGATION_FAILED')
    },

    resetContextObject({ commit, rootState, state }, allowTypes = contextObjectCollections.map(c => c.type)) {
      const type = state.contextObject?.['@type']
      let contextObject

      // If contextObject already set check its type and value
      if (type && allowTypes.includes(type)) {
        const collectionName = contextObjectCollections.find(c => c.type === type).collection
        const map = rootState.common[`${collectionName}Map`]
        if (map.has(state.contextObject['@id'])) return
        contextObject = rootState.common[collectionName][0] || null
      }

      if (!contextObject) {
        const { teachersMap, schoolClasses } = rootState.common
        const { teacher } = rootState.user
        // Is current user a teacher?
        if (teacher) {
          if (allowTypes.includes('Teacher')) {
            contextObject = teachersMap.get(teacher)
          } else if (allowTypes.includes('SchoolClass')) {
            contextObject = schoolClasses.find(c => c.teacher === teacher)
          }
        }
      }

      // Still nothing?
      // Falling back to the first item of the first allowed collection
      if (!contextObject) {
        const collections = Object.fromEntries(contextObjectCollections.map(c => [c.type, c.collection]))
        const firstAllowedCollection = collections[allowTypes[0]] || []
        contextObject = rootState.common[firstAllowedCollection][0] || null
      }

      commit('SET_CONTEXT_OBJECT', contextObject)
    }

  }

})
