import { api, getErrorReason } from '@/api'
import { router } from '@/router'
import qs from 'qs'
import User from '@/entities/user'


const state = {
  status: {}
}


const actions = {

  async login({ commit, dispatch }, payload) {
    commit('LOGIN_REQUEST')
    commit('SET_USER', null, {root: true})
    try {
      const user = (await api.post('/api/login', payload)).data
      await dispatch('afterLogin', user)
      commit('LOGIN_SUCCESS')
    } catch (error) {
      commit('LOGIN_ERROR', error)
      throw error
    }
  },

  async autologin({ dispatch }) {
    try {
      const user = (await api.get('/api/me')).data
      await dispatch('afterLogin', user)
    } catch (error) {
      if (401 === error.request?.status) {
        return null
      }
      console.error(error)
    }
  },

  async afterLogin({ commit }, userData) {
    commit('SET_USER', new User(userData), {root: true})
    try {
      const school = (await api.get('/api/school')).data
      commit('SET_SCHOOL', school, {root: true})
    } catch (error) {
      console.error(error)
    }
  },

  async logout({ dispatch }) {
    await api.post('/api/logout')
    dispatch('redirectToLogin')
  },

  redirectToLogin({ rootState: { user: { email }}}) {
    const username = email || null
    const currentPath = router.currentRoute.fullPath
    const query = {u: username, to: currentPath}
    location.assign('/login?' + qs.stringify(query))
  },

  initInterceptor({ dispatch, rootGetters }) {
    api.interceptors.response.use(undefined, error => {
      if (error.response?.status === 401 && rootGetters.isAuth) {
        dispatch('redirectToLogin')
      }
      throw error
    })
  }

}


const mutations = {

  LOGIN_REQUEST(state) {
    state.status = {isLoggingIn: true}
  },

  LOGIN_ERROR(state, error) {
    state.status = {
      error,
      reason: getErrorReason(error)
    }
  },

  LOGIN_SUCCESS(state) {
    state.status = {}
  }

}


export default {
  namespaced: true,
  state,
  actions,
  mutations
}
